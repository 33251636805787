import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Folder, File, Edit, Eye, Plus, ChevronDown, ChevronUp, Move, ChevronRight, Check, X, Clock, Copy, Trash2, Sun, Moon, LogOut, Maximize, Minimize } from 'lucide-react';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import CustomLinkDialog from './CustomLinkDialog';

const API_URL = 'https://trovedex.com:5000/api';

// Define the custom CSS
const customCSS = `
  .jodit-wysiwyg {
    font-family: Arial, sans-serif;
  }
  h1 {
    font-size: 2em;
    font-weight: bold;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
  }
  h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
  }
  h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1em;
    font-weight: bold;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
  }
  h5 {
    font-size: 0.83em;
    font-weight: bold;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
  }
  h6 {
    font-size: 0.67em;
    font-weight: bold;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
  }
  p {
    font-family: Verdana, sans-serif;
    font-size: 14px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  ul, ol {
    padding-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin-bottom: 0;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  blockquote {
    margin-left: 2em;
    margin-right: 2em;
    padding-left: 1em;
    border-left: 3px solid #ccc;
    font-style: italic;
  }
  pre {
    background-color: #f4f4f4;
    padding: 1em;
    overflow-x: auto;
  }
  code {
    font-family: monospace;
    background-color: #f4f4f4;
    padding: 0.2em 0.4em;
    border-radius: 3px;
  }
`;

const EditableName = ({ name, onUpdate, onCancel }) => {
  const [editedName, setEditedName] = useState(name);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onUpdate(editedName);
    } else if (e.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <div className="flex items-center">
      <input
        type="text"
        value={editedName}
        onChange={(e) => setEditedName(e.target.value)}
        onKeyDown={handleKeyDown}
        className="border rounded px-1 mr-2 bg-white dark:bg-gray-700 text-black dark:text-white"
        autoFocus
      />
      <button onClick={() => onUpdate(editedName)} className="mr-1">
        <Check size={18} className="text-green-500 hover:text-green-600" />
      </button>
      <button onClick={onCancel}>
        <X size={18} className="text-red-500 hover:text-red-600" />
      </button>
    </div>
  );
};

const App = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [moveTarget, setMoveTarget] = useState(null);
  const [editingName, setEditingName] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const editor = useRef(null);
  // for resizing the navigation pane
  const [navWidth, setNavWidth] = useState(375); // Default width of 256px (16rem)
  const [isDragging, setIsDragging] = useState(false);
  const [isWideMode, setIsWideMode] = useState(false);

  useEffect(() => {
    // Apply dark mode to the body
    document.body.classList.toggle('dark', darkMode);
  }, [darkMode]);

  // toggle functions
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  const toggleContentWidth = () => {
    setIsWideMode(!isWideMode);
  };

  const JoditRichEditor = ({ content, onUpdate }) => {
    const [showLinkDialog, setShowLinkDialog] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [joditEditor, setJoditEditor] = useState(null);

    const config = useMemo(() => ({
      readonly: false,
      height: 400,
      uploader: {
        insertImageAsBase64URI: true
      },
      style: {
        font: '14px Verdana'
      },
      defaultMode: '1',
      events: {
        beforeUpload: function (files) {
          const reader = new FileReader();
          reader.onload = function (e) {
            editor.current.selection.insertImage(e.target.result);
          };
          reader.readAsDataURL(files[0]);
          return false;
        },
        'openLinkDialog': (editor) => {
          setShowLinkDialog(true);
          setSelectedText(editor.selection.html);
        }
      },
      controls: {
        link: {
          popup: (editor) => {
            //console.log(editor);
            setJoditEditor(editor);
            editor.events.fire('openLinkDialog', editor);
            return false;
          }
        },
        unlink: {
          icon: 'unlink',
          exec: (editor) => {
            handleRemoveLink(editor);
          }
        }
      },
      extraButtons: ['link', 'unlink'],
      extraPlugins: ['copyformat'],
      useWitContentCSSForClass: false,
      contentCss: customCSS
    }), []);

    const handleRemoveLink = useCallback((editor) => {
      const selection = editor.selection;
      const range = selection.range;

      // If no text is selected, try to find the parent link
      if (range.collapsed) {
        const parentLink = selection.current().closest('a');
        if (parentLink) {
          range.selectNode(parentLink);
          selection.select(range);
        }
      }

      // Remove the link
      editor.execCommand('unlink');
    }, []);

    // sanitizes the HTML to remove any current link tags
    // from the selected text, and inserts the new link
    const handleInsertLink = useCallback((url, text) => {
      if (!joditEditor) return;

      const sanitizedUrl = DOMPurify.sanitize(url);
      const sanitizedText = DOMPurify.sanitize(text);

      // Create a temporary div to hold the sanitized HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = sanitizedText;

      // Remove any existing links within the text
      const links = tempDiv.getElementsByTagName('a');
      while (links[0]) {
        const parent = links[0].parentNode;
        while (links[0].firstChild) {
          parent.insertBefore(links[0].firstChild, links[0]);
        }
        parent.removeChild(links[0]);
      }

      // Get the cleaned text content
      const cleanedText = tempDiv.textContent || tempDiv.innerText || '';

      // Insert the new link
      const linkHtml = `<a href="${sanitizedUrl}">${cleanedText}</a>`;
      joditEditor.selection.insertHTML(linkHtml);
      setShowLinkDialog(false);
    }, [joditEditor]);

    return (
      <>
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
        />
        {showLinkDialog && (
          <CustomLinkDialog
            selection={selectedText}
            onInsert={handleInsertLink}
            onClose={() => setShowLinkDialog(false)}
          />
        )}
      </>
    );
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === 'A' && e.target.href.includes('/document/')) {
      e.preventDefault();
      const documentId = e.target.href.split('/').pop();
      handleItemSelect({ _id: documentId, type: 'document' });
    }
  };

  const handleStartEditing = () => {
    setIsEditMode(true);
    setEditedContent(selectedItem.content);
  };

  const handleFinishEditing = (reload) => {
    if (!editor) return;
    if (!editor.current) return;
    if (!isEditMode) return;
    setIsEditMode(false);
    if (editor.current.value !== selectedItem.content) {
      handleDocumentEdit(editor.current.value, reload);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchItems();
    }
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${API_URL}/documents`);
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
      if (error.response && error.response.status === 401) {
        // Token might be invalid or expired
        handleLogout();
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/login`, { password });
      const { token } = response.data;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
      fetchItems();
    } catch (error) {
      console.error('Login failed:', error);
      alert('Invalid password');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    setItems([]);
    setSelectedItem(null);
  };

  const handleCreateItem = (type) => {
    handleFinishEditing(false);
    //console.log("posting to database...");
    axios.post(`${API_URL}/documents`, {
      name: `New ${type} ${items.length + 1}`,
      type: type,
      content: type === 'document' ? '<p></p>' : undefined,
      folderId: null
    })
      .then(response => {
        //console.log('Response received:', response.data);
        //setItems([...items, response.data]);
        //console.log('Items updated');
        const item = {
          type: type,
          _id: response.data.insertedId
        };
        //console.log(item);
        // select the item (adds to list if not there already)
        handleItemSelect(item);
      })
      .catch(error => {
        console.error('Error creating item:', error);
        // Handle error (e.g., show error message to user)
      });
  };

  const handleNameEdit = (itemId, newName) => {
    const item = items.find(i => i._id === itemId);
    //const endpoint = item.type === 'folder' ? 'folders' : 'documents';
    const endpoint = 'documents';

    axios.put(`${API_URL}/${endpoint}/${itemId}`, { name: newName })
      .then(response => {
        //console.log('Name updated:', response.data);
        setItems(prevItems => prevItems.map(item =>
          item._id === itemId ? { ...item, name: newName } : item
        ));
        if (selectedItem && selectedItem._id === itemId) {
          setSelectedItem(response.data);
        }
        setEditingName(null);
      })
      .catch(error => {
        console.error('Error updating name:', error);
        // Handle error (e.g., show error message to user)
      });
  };

  // Add this new function to check if a move is valid
  const isValidMove = (itemToMove, targetFolder) => {
    // Moving to root is always valid
    if (targetFolder._id === 'root') return true;
    // Can't move an item into itself
    if (itemToMove._id === targetFolder._id) return false;
    if (targetFolder.type !== 'folder') return false;

    // If moving a folder, check that the target is not a descendant
    if (itemToMove.type === 'folder') {
      const isDescendant = (folder, targetId) => {
        if (folder._id === targetId) return true;
        return items
          .filter(item => item.folderId === folder._id)
          .some(child => child.type === 'folder' && isDescendant(child, targetId));
      };
      return !isDescendant(itemToMove, targetFolder._id);
    }

    // Documents can be moved anywhere
    return true;
  };

  const addUniqueItem = (newItem) => {
    setItems(prevItems => {
      // Check if an item with the same id already exists
      const itemExists = prevItems.some(item => item._id === newItem._id);

      if (!itemExists) {
        // If the item doesn't exist, add it to the array
        //console.log('Adding new item:', newItem);
        return [...prevItems, newItem];
      } else {
        // If the item already exists, return the previous array unchanged
        //console.log('Item already exists:', newItem);
        return prevItems;
      }
    });
  };

  const handleItemSelect = (item) => {
    if (moveTarget) {
      // Handle move operation
      if ((item.type === 'folder' || item._id === 'root') && isValidMove(moveTarget, item)) {
        console.log("Move operation");
        const newFolderId = item._id === 'root' ? null : item._id;
        axios.put(`${API_URL}/documents/${moveTarget._id}`, { newFolderId: newFolderId, oldFolderId: moveTarget.folderId })
          .then(() => {
            //console.log('Item moved');
            fetchItems(); // Refresh the items list
            setMoveTarget(null);
          })
          .catch(error => {
            console.error('Error moving item:', error);
            // Handle error (e.g., show error message to user)
          });
      } else {
        // Invalid move, provide feedback to user
        console.log("Invalid move");
        // You could set some state here to show an error message to the user
      }
    } else {
      console.log("Item selected...");
      console.log(item);
      // Select the item
      if (item.type === 'document' || item.type === 'folder') {
        axios.get(`${API_URL}/documents/${item._id}`)
          .then(response => {
            //console.log('Document fetched:', response.data);
            addUniqueItem(response.data);
            setSelectedItem(response.data);
            setIsEditMode(false);
          })
          .catch(error => {
            console.error('Error fetching document:', error);
            // Handle error (e.g., show error message to user)
          });
        // folder is the only other type, so don't really need the if
        // but I'm gonna anyway
      } else {
        setSelectedItem(item);
        setIsEditMode(false);
      }
    }
  };

  const handleDocumentEdit = useCallback((newContent, reload) => {
    if (selectedItem) {
      axios.put(`${API_URL}/documents/${selectedItem._id}`, { content: newContent })
        .then(response => {
          //console.log('Document updated:', response.data);
          if (reload) setSelectedItem(response.data);
          //setItems(items.map(item => item._id === response.data._id ? response.data : item));
        })
        .catch(error => {
          console.error('Error updating document:', error);
          // Handle error (e.g., show error message to user)
        });
    }
  }, [selectedItem, items]);

  const moveItem = (itemId, newParentId) => {
    setItems(prevItems => {
      const updatedItems = prevItems.map(item => {
        if (item._id === itemId) {
          return { ...item, parentId: newParentId };
        }
        if (item.type === 'folder') {
          if (item._id === newParentId) {
            return { ...item, children: [...item.children, itemId] };
          }
          if (item.children.includes(itemId)) {
            return { ...item, children: item.children.filter(id => id !== itemId) };
          }
        }
        return item;
      });
      setMoveTarget(null);
      return updatedItems;
    });
  };

  const toggleEditMode = () => {
    if (isEditMode)
      handleFinishEditing(true);
    else
      handleStartEditing();
  }

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const toggleFolderExpansion = (folderId, e) => {
    e.stopPropagation();
    //console.log(items);
    setItems(items.map(item =>
      item._id === folderId ? { ...item, isExpanded: !item.isExpanded } : item
    ));
  };

  const sortedItems = [...items].sort((a, b) => {
    if (a.type !== b.type) return a.type === 'folder' ? -1 : 1;
    if (sortOrder === 'asc') {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });

  const isFolderEmpty = (folderId) => {
    return !items.some(item => item.folderId === folderId);
  };

  const handleDeleteItem = (itemToDelete) => {
    if (itemToDelete.type === 'folder' && !isFolderEmpty(itemToDelete._id)) {
      alert("Cannot delete non-empty folder. Please delete or move its contents first.");
      return;
    }

    if (window.confirm(`Are you sure you want to delete "${itemToDelete.name}"?`)) {
      axios.delete(`${API_URL}/documents/${itemToDelete._id}`)
        .then(() => {
          console.log('Item deleted:', itemToDelete.name);
          fetchItems(); // Refresh the items list
          if (selectedItem && selectedItem._id === itemToDelete._id) {
            setSelectedItem(null); // Clear the selection if the deleted item was selected
          }
        })
        .catch(error => {
          console.error('Error deleting item:', error);
          // Handle error (e.g., show error message to user)
        });
    }
  };

  const handleCloneDocument = () => {
    if (selectedItem && selectedItem.type === 'document') {
      const clonedDocument = {
        name: `${selectedItem.name} (clone)`,
        type: 'document',
        content: selectedItem.content,
        folderId: selectedItem.folderId
      };

      axios.post(`${API_URL}/documents`, clonedDocument)
        .then(response => {
          //console.log('Document cloned:', response.data);
          fetchItems(); // Refresh the items list
          const item = {
            type: 'document',
            _id: response.data.insertedId
          };
          //console.log(item);
          // select the item (adds to list if not there already)
          handleItemSelect(item);
        })
        .catch(error => {
          console.error('Error cloning document:', error);
          // Handle error (e.g., show error message to user)
        });
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderItems = (parentId = null) => {
    const filteredItems = sortedItems.filter(item =>
      parentId ? item.folderId === parentId : !item.folderId
    );

    return filteredItems.map(item => {
      const isFolder = item.type === 'folder';
      const Icon = isFolder ? Folder : File;
      const isValidDropTarget = moveTarget && isValidMove(moveTarget, item);
      const isSelected = selectedItem && selectedItem._id === item._id;
      const isEmpty = isFolder ? isFolderEmpty(item._id) : true;

      return (
        <div key={item._id}>
          <div
            className={`flex items-center mb-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 p-1 rounded 
              ${selectedItem?._id === item._id ? 'bg-blue-100 dark:bg-sky-900' : ''} 
              ${isValidDropTarget ? 'border-2 border-dashed border-blue-500' : ''}`}
            onClick={() => { handleFinishEditing(false); handleItemSelect(item); }}
          >
            {isFolder && (
              <ChevronRight
                size={18}
                className={`mr-1 transform transition-transform ${item.isExpanded ? 'rotate-90' : ''}`}
                onClick={(e) => toggleFolderExpansion(item._id, e)}
              />
            )}
            <Icon size={18} className="mr-2" />
            {editingName === item._id ? (
              <EditableName
                name={item.name}
                onUpdate={(newName) => handleNameEdit(item._id, newName)}
                onCancel={() => setEditingName(null)}
              />
            ) : (
              <span className="flex-grow">{item.name}</span>
            )}
            {isSelected && (
              <Trash2
                size={18}
                className={`ml-2 cursor-pointer ${isEmpty ? 'text-red-500 hover:text-red-700' : 'text-gray-300 dark:text-gray-900'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteItem(item);
                }}
                title={isEmpty ? "Delete item" : "Cannot delete non-empty folder"}
              />
            )}
            {editingName !== item._id && (
              <Edit
                size={18}
                className="ml-2 cursor-pointer text-gray-500 hover:text-gray-700 dark:hover:text-gray-200"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingName(item._id);
                }}
              />
            )}
            <Move
              size={18}
              className={`ml-2 cursor-pointer ${moveTarget?._id === item._id ? 'text-blue-500 dark:text-blue-200' : 'text-gray-500 hover:text-gray-700 dark:hover:text-gray-200'}`}
              onClick={(e) => {
                e.stopPropagation();
                setMoveTarget(moveTarget?._id === item._id ? null : item);
              }}
            />
          </div>
          {isFolder && item.isExpanded && (
            <div className="ml-4">
              {renderItems(item._id)}
            </div>
          )}
        </div>
      );
    });
  };

  // mouse events
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      const newWidth = e.clientX;
      if (newWidth > 150 && newWidth < 1000) {  // Set min and max widths
        setNavWidth(newWidth);
      }
    }
  }, [isDragging]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
        <form onSubmit={handleLogin} className="bg-white dark:bg-gray-800 p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold mb-4 dark:text-white">Login</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="w-full p-2 mb-4 border rounded dark:bg-gray-700 dark:text-white"
          />
          <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
            Login
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className={`flex h-screen ${darkMode ? 'dark' : ''}`}>
      {/* Navigation Panel */}
      <div
        className={`bg-white dark:bg-gray-800 border-r overflow-auto relative ${darkMode ? 'text-white' : 'text-black'}`}
        style={{ width: `${navWidth}px`, minWidth: '150px', maxWidth: '1000px' }}
      >
        <div className="p-4"> {/* Added padding container */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold mb-4">Documents</h2>
            <div className="flex items-center">
              <button
                onClick={toggleDarkMode}
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-600 mr-2"
                title={darkMode ? "Switch to light mode" : "Switch to dark mode"}
              >
                {darkMode ? <Sun size={18} /> : <Moon size={18} />}
              </button>
              <button
                onClick={handleLogout}
                className="p-2 rounded-full bg-red-500 text-white hover:bg-red-600"
                title="Logout"
              >
                <LogOut size={18} />
              </button>
            </div>
          </div>
          <div className="mb-4 space-y-2">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded flex items-center w-full"
              onClick={() => handleCreateItem('document')}
            >
              <Plus size={18} className="mr-2" />
              New Document
            </button>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded flex items-center w-full"
              onClick={() => handleCreateItem('folder')}
            >
              <Plus size={18} className="mr-2" />
              New Folder
            </button>
          </div>
          <div className="mb-4">
            <button
              className="flex items-center text-sm text-gray-600  dark:text-gray-300"
              onClick={toggleSortOrder}
            >
              Sort {sortOrder === 'asc' ? <ChevronUp size={18} className="ml-1" /> : <ChevronDown size={18} className="ml-1" />}
            </button>
          </div>
          <div
            className={`p-2 mb-2 ${moveTarget && !moveTarget.parentId ? 'border-2 border-dashed border-blue-500' : ''}`}
            onClick={() => handleItemSelect({ _id: 'root' })}
          >
            Root
          </div>
          <div>
            {renderItems()}
          </div>
        </div>
      </div>

      {/* Resizer */}
      <div
        className="w-1 bg-gray-200 dark:bg-gray-600 cursor-col-resize hover:bg-gray-300 dark:hover:bg-gray-500 active:bg-gray-400 dark:active:bg-gray-400"
        onMouseDown={handleMouseDown}
      ></div>

      {/* Main Content Area */}
      <div className="flex-1 p-4 overflow-auto bg-gray-100 dark:bg-gray-900">
        <div className={`${isWideMode ? 'w-full' : 'max-w-3xl'} mx-auto`}>
          {selectedItem && selectedItem.type === 'document' ? (
            <div>
              <div className="flex justify-between items-center mb-4"><div>
                <h1 className="text-2xl font-bold dark:text-white">{selectedItem.name}</h1>
                <div className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  <p className="text-xs"><Clock size={16} className="inline mr-1" /> <strong>Created:</strong> {formatDate(selectedItem.createdAt)} /
                    <strong> Last updated:</strong> {formatDate(selectedItem.updatedAt)}</p>
                </div>
              </div>
                <div className="flex items-center">
                  {!isEditMode && (
                    <button
                      className="bg-gray-200 dark:bg-gray-700 p-2 rounded mr-2"
                      onClick={handleCloneDocument}
                      title="Clone document"
                    >
                      <Copy size={18} />
                    </button>
                  )}
                  <button
                    className="bg-gray-200 dark:bg-gray-700 p-2 rounded mr-2"
                    onClick={toggleEditMode}
                    title={isEditMode ? "Finish editing" : "Edit page"}
                  >
                    {isEditMode ? <Eye size={18} /> : <Edit size={18} />}
                  </button>
                  <button
                    className="bg-gray-200 dark:bg-gray-700 p-2 rounded"
                    onClick={toggleContentWidth}
                    title={isWideMode ? "Switch to narrow mode" : "Switch to wide mode"}
                  >
                    {isWideMode ? <Minimize size={18} /> : <Maximize size={18} />}
                  </button>
                </div>
              </div>
              <div className="bg-white dark:bg-gray-800 p-4 rounded shadow">
                {isEditMode ? (
                  <JoditRichEditor
                    content={editedContent}
                  //onUpdate={handleDocumentEdit}
                  />
                ) : (
                  <div
                    className="document-content dark:text-white"
                    dangerouslySetInnerHTML={{ __html: selectedItem.content }}
                    onClick={handleLinkClick}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-500 dark:text-gray-400 mt-20">
              {moveTarget ? 'Select a valid destination to move the item' : 'Select a document to view or edit'}
            </div>
          )}
        </div>
      </div>

      {/* CSS for document content and resizer */}
      <style jsx global>{`
        .document-content {
          font-family: Arial, sans-serif;
        }
        ${customCSS}
        body {
          cursor: ${isDragging ? 'col-resize' : 'auto'};
          user-select: ${isDragging ? 'none' : 'auto'};
        }
        .dark {
          background-color: #1a202c;
          color: #e2e8f0;
        }
        .dark .jodit-wysiwyg {
          background-color: #2d3748;
          color: #e2e8f0;
        }
        .dark .jodit-toolbar-button {
          color: #e2e8f0;
        }
        .dark .jodit-toolbar-button:hover {
          background-color: #4a5568;
        }
      `}</style>
    </div>
  );
};

export default App;