import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CustomLinkDialog = ({ selection, onInsert, onClose }) => {
  const [linkType, setLinkType] = useState('external');
  const [externalUrl, setExternalUrl] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [linkText, setLinkText] = useState(selection);

  useEffect(() => {
    if (!linkText) {
      setLinkText('');
    }
    // Fetch the list of documents when the component mounts
    axios.get('https://trovedex.com:5000/api/documents')
      .then(response => {
        // Filter out folders, keeping only documents
        const documentItems = response.data.filter(item => item.type === 'document');
        setDocuments(documentItems);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
      });
  }, []);

  const handleInsert = () => {
    const url = linkType === 'external' ? externalUrl : `/document/${selectedDocument}`;
    // Strip any existing link tags from the link text
    const strippedText = linkText.replace(/<\/?a[^>]*>/g, '');
    onInsert(url, strippedText);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg shadow-xl">
        <h2 className="text-xl mb-4">Insert Link</h2>
        <div className="mb-4">
          <input
            type="text"
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
            placeholder="Link Text"
            className="w-full p-2 border rounded mb-2"
          />
          <label className="mr-4">
            <input
              type="radio"
              value="external"
              checked={linkType === 'external'}
              onChange={() => setLinkType('external')}
            />
            &nbsp;External Link
          </label>
          <label>
            <input
              type="radio"
              value="internal"
              checked={linkType === 'internal'}
              onChange={() => setLinkType('internal')}
            />
            &nbsp;Internal Document
          </label>
        </div>
        {linkType === 'external' ? (
          <input
            type="text"
            value={externalUrl}
            onChange={(e) => setExternalUrl(e.target.value)}
            placeholder="Enter URL"
            className="w-full p-2 border rounded"
          />
        ) : (
          <select
            value={selectedDocument}
            onChange={(e) => setSelectedDocument(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select a document</option>
            {documents.map(doc => (
              <option key={doc._id} value={doc._id}>{doc.name}</option>
            ))}
          </select>
        )}
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleInsert}
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Insert
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomLinkDialog;